import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailLsPage = (props) => {
  return (
    <div> 
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/ls-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            Print - Event Invitation
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">For this project I was in charge of creating the invite for a private event. It had to be subtile and in line with the theme of the party which was: "Light & Shadow".</p>
            <p className="mb-4">Therefore, I decided to go with a see-through card concept. In order to be able to read the theme the invitee had to put the invite under a source of light. </p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">Private</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">Print</p>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2021</p>
          </div>
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailLsPage